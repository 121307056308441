// Our main CSS
import '../css/app.css'

/**
 * Vue is ready for you, if you need it.
 */

/**
 * The following block of code automatically registers your Vue components.
 * It will recursively scan this directory for the Vue components and
 * automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 *
 * Feel free to remove this block if you don't like black magic fuckery,
 * or you want to register your Vue components manually.
 */
/*
 *** https://v3.vuejs.org/
 */
// interface ImportedModule {
//   name: string
//   default: object
// }
// const vueSingleFileComponents = import.meta.glob<ImportedModule>('./**/*.vue', {
//   eager: true
// })

// import { createApp } from 'vue'
// const app = createApp({})

// for (const path in vueSingleFileComponents) {
//   const importedModule = vueSingleFileComponents[String(path)]
//   const name = path.split('/').pop()?.split('.')[0]
//   if (name !== undefined && typeof name === 'string') {
//     app.component(name, importedModule.default)
//   }
// }
// app.mount('#vue-app')

/**
OR:

Load a js-module dynamically, if the parent element exists, in this example, a vue component. (The js-file wont load if html element with id #ExampleVueComponentWrapper does not exists)
*/

/**
const ExampleVueComponentWrapper = document.getElementById(
  'ExampleVueComponentWrapper'
)
if (ExampleVueComponentWrapper && ExampleVueComponentWrapper !== null) {
  import('./ExampleVueComponent/index.js')
    .then((module) => {
      module.default(ExampleVueComponentWrapper as HTMLElement)
    })
    .catch((err) => {
      console.log(err)
      // main.textContent = err.message;
    })
}
*/

/**
 * 🦄: Now do your magic.
 */

const EmailFormWrapper = document.getElementById('EmailFormWrapper')
if (EmailFormWrapper && EmailFormWrapper !== null) {
  import('./EmailForm/index.ts')
    .then((module) => {
      module.default(EmailFormWrapper as HTMLElement)
    })
    .catch((err) => {
      console.log(err)
    })
}

const mainMenu = document.getElementById('main-menu')

if (mainMenu) {
  import('./Navigation/index.ts')
    .then((module) => {
      module.default(mainMenu as HTMLElement)
    })
    .catch((err) => {
      console.log(err)
    })
}

const agMainMenu = document.getElementById('ag-main-menu')

if (agMainMenu) {
  import('./AGNavigation/index.ts')
    .then((module) => {
      module.default(agMainMenu as HTMLElement)
    })
    .catch((err) => {
      console.log(err)
    })
}

if (document.querySelector('.copy-btn')) {
  import('./copy-btn').then((module) => {
    module.default()
  })
}

if (document.querySelectorAll('.splide-image-quote').length > 0) {
  import('./splide-image-quote.ts').then((module) => {
    const splides = document.querySelectorAll('.splide-image-quote')
    splides.forEach((splide) => {
      module.default(splide)
    })
  })
}

if (document.querySelectorAll('.splide-images').length > 0) {
  import('./splide-images.ts').then((module) => {
    const splides = document.querySelectorAll('.splide-images')
    splides.forEach((splide) => {
      module.default(splide)
    })
  })
}

if (document.querySelectorAll('.splide-cards').length > 0) {
  import('./splide-cards.ts').then((module) => {
    const splides = document.querySelectorAll('.splide-cards')
    splides.forEach((splide) => {
      module.default(splide)
    })
  })
}
